<template>
  <div class="main-content white">
    <div class="container">
      <div class="data-container">
        <div class="data-head">
          <div class="data-row">
            <div class="item buyer">購買者</div>
            <div class="item qty">數量</div>
            <div class="item price">銷售金額</div>
          </div>
        </div>
        <div class="data-body">
          <div
            class="data-row"
            v-for="order in orders"
            :key="`group-cancel-order-${order.id}`"
          >
            <div class="item buyer">{{ order.buyer_name }}</div>
            <div class="item qty">{{ order.items_count }}</div>
            <div class="item price">${{ order.total_price }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-btn-block">
      <a
        class="btn-cta"
        data-toggle="modal"
        data-target="#modal-helper-group-fail"
      >
        流團通知
      </a>
    </div>

    <div
      class="modal fade"
      id="modal-helper-group-fail"
      tabindex="-1"
      role="dialog"
      aria-labelledby="delalert"
    >
      <div class="modal-dialog modal-sm modal-helper">
        <div class="modal-content">
          <div class="modal-title">流團通知</div>
          <div class="helper-content">
            點擊確認後活動狀態將改成流團，請再次確認是否要變更
          </div>
          <div class="modal-btn-block">
            <div class="modal-btn cancel" data-dismiss="modal">取消</div>
            <a class="modal-btn" @click.prevent="cancel">
              確定
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFailedFollowList, cancel } from '@/api/group-buying';
export default {
  data() {
    return {
      orders: []
    };
  },
  computed: {
    groupId() {
      return this.$route.params.group_id;
    }
  },
  watch: {
    groupId: {
      immediate: true,
      handler() {
        this.setData();
      }
    }
  },
  methods: {
    setData() {
      getFailedFollowList(this.groupId).then(data => {
        this.orders = data;
      });
    },
    cancel() {
      $('#modal-helper-group-fail').modal('hide');

      this.$nextTick(() => {
        cancel({
          group_buying_order_id: this.groupId
        }).then(() => {
          this.$router.push({
            name: 'CreatedGroupNotification',
            params: {
              group_id: this.groupId,
              type: 'cancel'
            }
          });
        });
      });
    }
  }
};
</script>
